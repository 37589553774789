import { render, staticRenderFns } from "./FrameworkIcon.vue?vue&type=template&id=16d7ceab"
import script from "./FrameworkIcon.vue?vue&type=script&lang=js"
export * from "./FrameworkIcon.vue?vue&type=script&lang=js"
import style0 from "./FrameworkIcon.vue?vue&type=style&index=0&id=16d7ceab&prod&lang=less"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports